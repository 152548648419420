import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExerciseService } from '../exercise/exercise.service';
import { ExerciseModel } from '../../models/exercise.model';

@Injectable({
	providedIn: 'root',
})
export class ExerciseStoreService {
	private readonly _exercises: BehaviorSubject<ExerciseModel[]>;
	private _isInitialized: BehaviorSubject<boolean>;

	readonly exercises$: Observable<ExerciseModel[]>;
	readonly isInitialized$: Observable<boolean>;

	constructor(private exerciseService: ExerciseService) {
		this._isInitialized = new BehaviorSubject<boolean>(false);
		this._exercises = new BehaviorSubject<ExerciseModel[]>([]);
		this.exercises$ = this._exercises.asObservable();
		this.isInitialized$ = this._isInitialized.asObservable();
	}

	get exercises() {
		return this._exercises.getValue();
	}

	set exercises(val: ExerciseModel[]) {
		this._exercises.next(val);
	}

	public async initialize(): Promise<void> {
		if (this._isInitialized.getValue()) {
			return;
		}

		this.exercises = await this.exerciseService.getAllExercises();
		this._isInitialized.next(true);
	}
}
