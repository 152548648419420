<mat-dialog-content class="h-[500px]">
	@if (isLoading) {
		<div class="flex justify-center items-center h-full">
			<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
		</div>
	}
	@if (hasError) {
		<p>There was a problem loading your subscription.</p>
	}

	@if (manageSubscriptionLink) {
		<iframe
			[src]="manageSubscriptionLink"
			class="h-full w-full"
			(load)="onLoaded($event)"
			(error)="onError()"
			[hidden]="isLoading"></iframe>
	}
</mat-dialog-content>
<div mat-dialog-actions class="flex">
	<button matDialogClose mat-flat-button class="gfx-button-primary flex-1" (click)="onClose()">Close</button>
</div>
