import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICountryReadModel } from './models/country.types';
import { Observable } from 'rxjs';

@Injectable()
export class CountryService {
	constructor(private http: HttpClient) {}

	getCountries(): Observable<ICountryReadModel[]> {
		return this.http.get<ICountryReadModel[]>('/api/countries');
	}
}
