import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
	HttpRequest,
	HttpErrorResponse,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { GenericErrorDialogComponent } from '../generic-error-dialog/generic-error-dialog.component';
import { isPlatformBrowser } from '@angular/common';

// Add this constant at the top of the file
export const HTTP_INTERCEPTOR_SKIP = new HttpContextToken<boolean>(() => false);

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private dialogService: MatDialog,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Skip interceptor if context is set
		if (request.context.get(HTTP_INTERCEPTOR_SKIP)) {
			return next.handle(request);
		}

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				// Your existing error handling logic
				if (error.status !== 404) {
					// Only show dialog for non-404 errors
					if (isPlatformBrowser(this.platformId)) {
						this.dialogService.open(GenericErrorDialogComponent, {
							autoFocus: false,
							data: {
								title: 'Error With Request',
								message: `Error ${error.status}: ${error.message}`,
							},
						});
					}
				}
				return throwError(() => error);
			}),
		);
	}
}
