import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IStateReadModel } from './models/states.types';

@Injectable()
export class StateService {
	constructor(private http: HttpClient) {}

	getStates(): Observable<IStateReadModel[]> {
		return this.http.get<IStateReadModel[]>('/api/states');
	}
}
