import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import {
	ICreateSubscriptionRequest,
	IHostedPaymentPageReadModel,
	IProcessHostedPaymentPageRequest,
	ISubscriptionReadModel,
} from './models/subscription.types';

@Injectable()
export class SubscriptionService {
	constructor(private http: HttpClient) {}

	async getSubscription(): Promise<ISubscriptionReadModel> {
		return await lastValueFrom(this.http.get<ISubscriptionReadModel>('/api/subscriptions'));
	}

	async getHostedPaymentPage(planFrequency: 'monthly' | 'yearly'): Promise<IHostedPaymentPageReadModel> {
		return await lastValueFrom(
			this.http.get<IHostedPaymentPageReadModel>('/api/subscriptions/hosted-payment-page', {
				params: { returnUrlSubdirectory: 'subscribe-success', planFrequency: planFrequency },
			}),
		);
	}

	processHostedPaymentPageResult(request: IProcessHostedPaymentPageRequest): Observable<void> {
		return this.http.post<void>('/api/subscriptions/hosted-payment-page', request);
	}

	createSubscription(request: ICreateSubscriptionRequest): Observable<ISubscriptionReadModel> {
		return this.http.post<ISubscriptionReadModel>('/api/subscriptions', request);
	}

	async cancelSubscription(): Promise<void> {
		await lastValueFrom(this.http.delete<void>('/api/subscriptions'));
	}

	async reactivateSubscription(): Promise<ISubscriptionReadModel> {
		return await lastValueFrom(this.http.post<ISubscriptionReadModel>('/api/subscriptions/reactivate', {}));
	}
}
