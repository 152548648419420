import { CanActivateFn, Router } from '@angular/router';
import { PLATFORM_ID, inject } from '@angular/core';
import { UserStoreService } from '../services/stores/user-store.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

export const authCanActivateFn: CanActivateFn = async () => {
	const router = inject(Router);
	const document = inject(DOCUMENT);
	const platformId = inject(PLATFORM_ID);
	const userStoreService = inject(UserStoreService);
	await userStoreService.initialize();
	const userDataModel = userStoreService.userData$();

	if (!userDataModel.isAuthenticated() && isPlatformBrowser(platformId)) {
		const currentUrl = document.location;
		router.navigate(['/login'], { queryParams: { returnUrl: currentUrl } });

		return false;
	}

	return userDataModel.isAuthenticated();
};

export const sysAdminCanActivateFn: CanActivateFn = async () => {
	const userStoreService = inject(UserStoreService);
	await userStoreService.initialize();
	const userDataModel = userStoreService.userData$();

	if (!userDataModel.isAuthenticated()) {
		return false;
	}

	return userDataModel.hasSystemAdminRole();
};

export const programAdminCanActivateFn: CanActivateFn = async () => {
	const userStoreService = inject(UserStoreService);
	await userStoreService.initialize();
	const userDataModel = userStoreService.userData$();

	if (!userDataModel.isAuthenticated()) {
		return false;
	}

	return userDataModel.hasProgramAdminRole() || userDataModel.hasSystemAdminRole();
};

export const programManagerCanActivateFn: CanActivateFn = async () => {
	const userStoreService = inject(UserStoreService);
	await userStoreService.initialize();
	const userDataModel = userStoreService.userData$();

	if (!userDataModel.isAuthenticated()) {
		return false;
	}

	return (
		userDataModel.hasProgramManagerRole() || userDataModel.hasProgramAdminRole() || userDataModel.hasSystemAdminRole()
	);
};

export const instructorCanActivateFn: CanActivateFn = async () => {
	const userStoreService = inject(UserStoreService);
	await userStoreService.initialize();
	const userDataModel = userStoreService.userData$();

	if (!userDataModel.isAuthenticated()) {
		return false;
	}

	return (
		userDataModel.hasSystemAdminRole() ||
		((userDataModel.hasActiveSubscription() || userDataModel.hasActiveTrialSubscription()) &&
			(userDataModel.hasInstructorRole() ||
				userDataModel.hasProgramManagerRole() ||
				userDataModel.hasProgramAdminRole()))
	);
};

export const instructorNoSubscriptionCanActivateFn: CanActivateFn = async () => {
	const userStoreService = inject(UserStoreService);
	await userStoreService.initialize();
	const userDataModel = userStoreService.userData$();

	if (!userDataModel.isAuthenticated()) {
		return false;
	}

	return (
		userDataModel.hasInstructorRole() ||
		userDataModel.hasProgramManagerRole() ||
		userDataModel.hasProgramAdminRole() ||
		userDataModel.hasSystemAdminRole()
	);
};

export const auth0CanActivateFn: CanActivateFn = async () => {
	return true;
};
