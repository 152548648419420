import { Component, HostListener, Inject, OnInit, signal } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { ProgramModel, ProgramFormatModel } from '../../../../models/program.model';
import { ReleaseModel } from '../../../../models/release-model';
import { ProgramStoreService } from '../../../../services/stores/program-store.service';
import { ReleaseStoreService } from '../../../../services/stores/release-store.service';
import { UserStoreService } from '../../../../services/stores/user-store.service';

export interface PlaylistDialogContext {
	programId: string | undefined;
	programFormatId: string | undefined;
	playlistName: string | null;
	sourceReleaseId: string | undefined;
}

@Component({
	selector: 'app-playlist-dialog',
	templateUrl: './playlist-dialog.component.html',
	styleUrls: ['./playlist-dialog.component.scss'],
})
export class PlaylistDialogComponent implements OnInit {
	programList: ProgramModel[] = [];
	filteredProgramFormats: ProgramFormatModel[] = [];
	programIsPreset: boolean = false;
	programFormatIsPreset: boolean = false;
	sourceReleaseIsPreset: boolean = false;
	selectedProgram: ProgramModel | undefined = undefined;
	selectedProgramFormat = {} as ProgramFormatModel;
	copySource: 'empty-playlist' | 'existing-release' = 'empty-playlist';
	filteredReleaseList: ReleaseModel[] = [];

	form = this.formBuilder.group({
		program: ['', Validators.required],
		programFormat: ['', Validators.required],
		playlistName: ['', Validators.required],
		playlistDescription: [''],
		sourceReleaseId: [''],
	});

	creatingPlaylist$ = signal(false);

	constructor(
		private programStoreService: ProgramStoreService,
		private releaseStoreService: ReleaseStoreService,
		private userStoreService: UserStoreService,
		private formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) private playlistDialogContext: PlaylistDialogContext,
		private dialogRef: MatDialogRef<PlaylistDialogContext>,
	) {}

	async ngOnInit(): Promise<void> {
		const allPrograms = this.programStoreService.programs;
		const userData = this.userStoreService.userData$();

		const userSelectedPrograms = userData?.getUserData()?.getSelectedPrograms();

		if (userSelectedPrograms !== undefined && userSelectedPrograms.length > 0) {
			allPrograms.forEach((program) => {
				userSelectedPrograms.forEach((selectedProgram) => {
					if (program.getProgramId() === selectedProgram.getProgramId()) {
						this.programList.push(program);
					}
				});
			});
		} else {
			this.programList = allPrograms;
		}

		if (this.playlistDialogContext.programId) {
			this.selectedProgram = this.programList.find((x) => x.getProgramId() === this.playlistDialogContext.programId)!;
			this.programIsPreset = true;
			this.form.get('program')?.setValue(this.playlistDialogContext.programId);
			if (this.programIsPreset) {
				this.form.get('program')?.disable();
			}
			this.filterProgramFormats(this.playlistDialogContext.programId);
			this.filteredReleaseList = (await this.releaseStoreService.getReleases()).filter(
				(x) => x.getProgramId() === this.playlistDialogContext.programId,
			);

			if (this.playlistDialogContext.programFormatId) {
				this.selectedProgramFormat = this.selectedProgram
					.getProgramFormats()
					.find((x) => x.getProgramFormatId() === this.playlistDialogContext.programFormatId)!;
				this.programFormatIsPreset = true;
				this.form.get('programFormat')?.setValue(this.playlistDialogContext.programFormatId);
			}

			this.form.get('playlistName')?.setValue(this.playlistDialogContext.playlistName);

			if (this.playlistDialogContext.sourceReleaseId) {
				this.sourceReleaseIsPreset = true;
				this.copySource = 'existing-release';
				this.form.get('sourceReleaseId')?.setValue(this.playlistDialogContext.sourceReleaseId);
			}
		}
	}

	@HostListener('keydown.escape') onEscape() {
		this.close();
	}

	@HostListener('keydown.enter') onEnter() {
		this.save();
	}

	async programSelectionChanged($event: MatSelectChange): Promise<void> {
		const value = $event.value;
		if (value === null || value === undefined) {
			this.selectedProgram = undefined;
			this.form.get('programFormat')?.disable();
			this.filteredProgramFormats = [];
			this.filteredReleaseList = [];
			return;
		}

		this.selectedProgram = this.programList.find((x) => x.getProgramId() === value)!;
		this.form.get('programFormat')?.enable();

		this.filterProgramFormats(value);

		const allReleases = await this.releaseStoreService.getReleases();
		this.filteredReleaseList = (await this.releaseStoreService.getReleases()).filter((x) => x.getProgramId() === value);
	}

	filterProgramFormats(programId: string) {
		const matchingProgram = this.programList.find((x) => x.getProgramId() === programId);
		if (matchingProgram === undefined) {
			return;
		}

		this.filteredProgramFormats = matchingProgram.getProgramFormats();
		if (this.filteredProgramFormats.length === 1) {
			this.form.get('programFormat')?.setValue(this.filteredProgramFormats[0].getProgramFormatId());
		}
	}

	programFormatSelectionChanged($event: MatSelectChange) {
		const value = $event.value;
		if (value === null || value === undefined) {
			return;
		}

		const matchingProgramFormat = this.filteredProgramFormats.find((x) => x.getProgramFormatId() === value);
		if (matchingProgramFormat === undefined) {
			return;
		}
	}

	changeCopySource($event: MatRadioChange): void {
		this.copySource = $event.value;
	}

	close() {
		this.dialogRef.close();
	}

	save() {
		this.creatingPlaylist$.set(true);
		let programId: string | null | undefined = this.playlistDialogContext.programId;
		let programFormatId: string | null | undefined = this.playlistDialogContext.programFormatId;
		if (!this.programIsPreset) {
			programId = this.form.get('program')?.value;
			programFormatId = this.form.get('programFormat')?.value;
		}

		this.dialogRef.close({
			program: programId,
			programFormat: programFormatId,
			playlistName: this.form.get('playlistName')?.value,
			playlistDescription: this.form.get('playlistDescription')?.value,
			sourceReleaseId: this.form.get('sourceReleaseId')?.value,
		});

		this.creatingPlaylist$.set(false);
	}
}
