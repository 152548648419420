@if (orientation === 'vertical') {
	<!-- Veritical slide in/out -->
	<div
		class="border-2 border-gfx-primary rounded-lg z-20 fixed bottom-0 left-0 md:left-1/4 w-full md:w-1/2 h-3/4 bg-gradient-radial from-white to-zinc-200 dark:from-gfx-cardbg dark:to-gfx-bg-end-gradient"
		[@slideUpDown]="displayState">
		<div class="h-full w-full">
			<button mat-stroked-button class="m-2" (click)="onCloseAction()">Close</button>
			<ngx-extended-pdf-viewer
				[src]="pdfUrl"
				[height]="'100%'"
				[showToolbar]="false"
				[authorization]="'bearerToken'"></ngx-extended-pdf-viewer>
		</div>
	</div>
} @else {
	<!-- Horizontal slide in/out -->
	<div
		class="border-2 border-gfx-primary rounded-lg z-20 relative 0 right-0 h-full w-full bg-gradient-radial from-white to-zinc-200 dark:from-gfx-cardbg dark:to-gfx-bg-end-gradient"
		[@slideHorizontally]="displayState">
		<div class="h-full w-full">
			<button mat-stroked-button class="m-2" (click)="onCloseAction()">Close</button>
			<ngx-extended-pdf-viewer
				[src]="pdfUrl"
				[height]="'100%'"
				[showToolbar]="false"
				[authorization]="'bearerToken'"></ngx-extended-pdf-viewer>
			s
		</div>
	</div>
}
