import { trigger, state, style, transition, animate } from '@angular/animations';

export const slideUpDownAnimation = trigger('slideUpDown', [
	state(
		'closed',
		style({
			transform: 'translateY(100%)',
			opacity: 0,
		}),
	),
	state(
		'open',
		style({
			transform: 'translateY(0%)',
			opacity: 1,
		}),
	),
	transition('closed => open', animate('300ms ease-in')),
	transition('open => closed', animate('300ms ease-out')),
]);
