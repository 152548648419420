import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToolbarService {
	sidenavState = new BehaviorSubject<boolean>(false);
	currentSidenavState$ = this.sidenavState.asObservable();

	toggleSidenav() {
		this.sidenavState.next(!this.sidenavState.value);
	}

	getToolbarExpandedSetting(): boolean {
		if (localStorage === undefined) {
			return true;
		}

		const toolbarExpanded = localStorage.getItem('gfxToolbarExpanded');
		return toolbarExpanded === 'true';
	}

	setToolbarExpandedSetting(value: boolean) {
		if (localStorage === undefined) {
			return;
		}
		localStorage.setItem('gfxToolbarExpanded', value.toString());
	}
}
