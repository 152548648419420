// confetti.service.ts
import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';

@Injectable({
	providedIn: 'root',
})
export class ConfettiService {
	celebrate() {
		const duration = 5000;

		confetti({
			particleCount: 150,
			spread: 180,
			colors: ['#673ab7', '#ffd740'],
		});

		setTimeout(() => confetti.reset(), duration);
	}
}
