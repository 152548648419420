<h1 mat-dialog-title class="pb-1">New Class Playlist</h1>
<div class="max-w-4xl flex">
	<mat-dialog-content [formGroup]="form">
		<div class="grid grid-cols-2 gap-2">
			<div class="col-start-1">
				@if (!programIsPreset) {
					<mat-form-field subscriptSizing="dynamic" appearance="fill">
						<mat-label>Programs</mat-label>
						<mat-select
							formControlName="program"
							placeholder="Choose the Program"
							(selectionChange)="programSelectionChanged($event)">
							@for (program of programList; track program.getProgramId()) {
								<mat-option [value]="program.getProgramId()">{{ program.getProgramName() }}</mat-option>
							}
						</mat-select>
					</mat-form-field>
				} @else {
					<mat-form-field subscriptSizing="dynamic" appearance="fill">
						<mat-label>Program</mat-label>
						<input matInput placeholder="Program" [value]="selectedProgram?.getProgramName()" />
					</mat-form-field>
				}
			</div>

			<div class="col-start-2">
				@if (!programFormatIsPreset) {
					<mat-form-field subscriptSizing="dynamic" class="hidden md:hidden" appearance="fill">
						<mat-label>Program Format</mat-label>
						<mat-select
							formControlName="programFormat"
							placeholder="Choose the Program Format"
							(selectionChange)="programFormatSelectionChanged($event)">
							@for (programFormat of filteredProgramFormats; track programFormat.getProgramFormatId()) {
								<mat-option [value]="programFormat.getProgramFormatId()">{{
									programFormat.getFormatName()
								}}</mat-option>
							}
						</mat-select>
					</mat-form-field>
				} @else {
					<mat-form-field appearance="fill">
						<mat-label>Program Format</mat-label>
						<input matInput placeholder="Program" [value]="this.selectedProgramFormat.getFormatName()" disabled />
					</mat-form-field>
				}
			</div>

			<mat-form-field subscriptSizing="dynamic" class="col-start-1 col-span-2" appearance="fill">
				<mat-label>Playlist Name</mat-label>
				<input matInput formControlName="playlistName" placeholder="i.e., BP Mix 8" />
			</mat-form-field>
			<mat-form-field subscriptSizing="dynamic" class="col-start-1 col-span-2" appearance="fill">
				<mat-label>Playlist Description</mat-label>
				<textarea
					matInput
					#playlistDescription
					formControlName="playlistDescription"
					maxlength="1000"
					placeholder="i.e., ☀️ Summertime! 😎"></textarea>
				<mat-hint align="end">{{ playlistDescription.value.length }} / 1000</mat-hint>
			</mat-form-field>

			@if (!sourceReleaseIsPreset) {
				<mat-radio-group
					class="col-start-1 col-span-2"
					[value]="copySource"
					[disabled]="selectedProgram === undefined"
					(change)="changeCopySource($event)">
					<div class="grid grid-cols-1">
						<mat-radio-button [value]="'empty-playlist'" (change)="copySource = 'empty-playlist'"
							>Create empty playlist</mat-radio-button
						>
						<mat-radio-button [value]="'existing-release'" (change)="copySource = 'existing-release'"
							>Copy from Release</mat-radio-button
						>
					</div>
				</mat-radio-group>

				@if (copySource === 'existing-release') {
					@if (filteredReleaseList.length === 0) {
						<p class="col-start-1 col-span-2">There are no available releases for this program.</p>
					} @else {
						<mat-form-field class="col-start-1 col-span-2" appearance="fill">
							<mat-label>Release</mat-label>
							<mat-select formControlName="sourceReleaseId" placeholder="Choose the Release">
								@for (release of filteredReleaseList; track release.getReleaseId()) {
									<mat-option [value]="release.getReleaseId()">{{ release.getReleaseName() }}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					}
				}
			}

			<mat-dialog-actions align="end" class="col-start-1 col-span-2">
				<button mat-flat-button [disabled]="creatingPlaylist$()" (click)="save()">Create</button>
				<button mat-button (click)="close()">Cancel</button>
			</mat-dialog-actions>
		</div>
	</mat-dialog-content>
</div>
