<div class="max-w-lg">
	<fieldset class="border-2 border-gfx-primary rounded-lg p-2">
		<legend class="font-bold p-2">Subscription Status</legend>
		@if (!loading$()) {
			<div class="grid grid-cols-2">
				<div class="font-bold">Subscription Status:</div>
				<div>{{ getSubscriptionStatus() }}</div>
				@if (nextBillingCycleDate != '') {
					<div class="font-bold">Next Billing Date:</div>
					<div>{{ nextBillingCycleDate }}</div>
				}

				@if (trialEndDate !== '') {
					<div class="font-bold">Trial End Date:</div>
					<div>{{ trialEndDate }}</div>
				}

				<div class="font-bold">Billing Cycle:</div>
				<div class="flex items-center gap-4">
					<div>{{ billingPeriodUnit }}</div>
					<a
						class="text-xs"
						(click)="showManageSubscriptionDialog(billingPeriodUnit === 'Monthly' ? 'Yearly' : 'Monthly')"
						>Change to {{ billingPeriodUnit === 'Monthly' ? 'Yearly' : 'Monthly' }}</a
					>
				</div>
			</div>

			@if (!hasActiveSubscription$() && !subscriptionWillExpire$()) {
				<div class="m-4 p-4 rounded-lg shadow-md border-2 bg-gfx-warning-panel text-black">
					<mat-icon class="text-orange-400">warning</mat-icon>
					<span class="ml-2 text-black"
						>You do not have an active subscription.
						<a routerLink="/create-subscription" class="underline">Subscribe</a> now for access to GFX's features.</span
					>
				</div>
			}

			@if (subscriptionWillExpire$()) {
				<div class="m-4 p-4 rounded-lg shadow-md border-2 bg-gfx-warning-panel text-black">
					<mat-icon color="warn">warning</mat-icon>
					<span class="ml-2"
						>Your subscription will expire on {{ nextBillingCycleDate }}.
						<a (click)="reactivateSubscription()" class="underline hover:cursor-pointer">Reactivate</a> now to renew
						access to GFX's features.</span
					>
				</div>
			}

			@if (subscriptionHasExpired$()) {
				<div class="m-4 p-4 rounded-lg shadow-md border-2 bg-gfx-warning-panel text-black">
					<mat-icon color="warn">warning</mat-icon>
					<span class="ml-2"
						>Your subscription expired on {{ nextBillingCycleDate }}.
						<a (click)="reactivateSubscription()" class="underline hover:cursor">Reactivate</a> now to renew access to
						GFX's features.</span
					>
				</div>
			}

			@if (hasActiveSubscription$()) {
				<button mat-flat-button class="gfx-button-primary mt-4" (click)="showManageSubscriptionDialog()">
					Manage Subscription
				</button>
			}

			@if (cancelMessage$()) {
				<div>
					<p>{{ cancelMessage$() }}</p>
				</div>
			}
		}
	</fieldset>
</div>
