import { trigger, state, style, transition, animate } from '@angular/animations';

export const slideHorizontallyAnimation = trigger('slideHorizontally', [
	state(
		'closed',
		style({
			transform: 'translateX(100%)',
			opacity: 0,
		}),
	),
	state(
		'open',
		style({
			transform: 'translateX(0%)',
			opacity: 1,
		}),
	),
	transition('closed => open', animate('300ms ease-in')),
	transition('open => closed', animate('300ms ease-out')),
]);
